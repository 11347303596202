<template>
  <v-container>
    <FilterRow
      v-if="hasFilters"
      :filters="filters"
      :hasFilters="hasFilters"
      @resetFilter="resetFilter"
      ref="FilterRow"
    />
    <JingleList
      v-if="viewAsList"
      :collections="collections"
      :disabled="disabled"
      :scrollerHeight="scrollerHeight"
      :jingles="jingles"
      :newThreshold="newThreshold"
      :key="filterHash"
      @jingleClicked="onJingleClicked"
      @collectionToggled="onCollectionToggled"
    />
    <Jingles
      v-else
      :collections="collections"
      :disabled="disabled"
      :scrollerHeight="scrollerHeight"
      :jingles="jingles"
      :newThreshold="newThreshold"
      :itemsPerRow="itemsPerRow"
      :key="filterHash"
      @jingleClicked="onJingleClicked"
      @collectionToggled="onCollectionToggled"
    />
  </v-container>
</template>

<script>
import Vue from "vue";
import FilterRow from "@/components/FilterRow";
import Jingles from "@/components/Jingles";
import JingleList from "@/components/JingleList";
import { JingleIterator } from "@/jingleIterator.js";
export default {
  name: "JinglesView",
  props: {
    eventBus: {
      type: Object, // TODO use a data-class
      required: true,
    },
    filters: {
      type: Object, // TODO use a data-class
      required: true,
    },
    viewAsList: {
      type: Boolean,
      default: false,
    },
    collections: {
      type: Object, // TODO use data-class
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    scrollerHeight: {
      type: Number,
      required: true,
    },
    jingles: {
      type: JingleIterator,
      required: true,
    },
    newThreshold: {
      // Move this into "settings"
      type: Number,
      default: 7,
    },
    itemsPerRow: {
      // Move this into "settings"
      type: Number,
      required: true,
    },
  },
  data: () => ({
    filterHash: 1,
  }),
  methods: {
    resetFilter: function () {
      this.$emit("resetFilter", ...arguments);
    },
    onJingleClicked: function () {
      this.$emit("jingleClicked", ...arguments);
    },
    onCollectionToggled: function () {
      this.$emit("collectionToggled", ...arguments);
    },
  },
  computed: {
    hasFilters: function () {
      // TODO: Move this into JingleIterator
      if (!this.filters) {
        return false;
      }
      let output =
        this.filters.word !== "" ||
        this.filters.maxDuration !== 0.0 ||
        this.filters.favouritesOnly ||
        this.filters.category !== "" ||
        this.filters.newOnly ||
        // (this.filters.collection ? this.filters.collection.toBool() : false);
        (this.filters.collection ? this.filters.collection.name !== "" : false);
      return output;
    },
  },
  watch: {
    filters: {
      handler: function () {
        this.filterHash += 1;
      },
      deep: true,
    },
    hasFilters: function () {
      let self = this;
      Vue.nextTick(function () {
        self.$emit("scrollHeightChangeRequest");
      });
    },
  },
  components: {
    FilterRow,
    JingleList,
    Jingles,
  },
};
</script>
