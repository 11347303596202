<template>
  <v-container>
    <v-row align-content="space-between">
      <v-col cols="12">
        <v-card
          outlined
          :class="buttonClass"
          @click="shoutJingle(jingle.id)"
          :key="jingle.id"
          height="120"
          :disabled="disabled"
        >
          <v-img
            :src="img_url"
            class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.9)"
            height="118"
            position="top"
          >
            <v-card-title class="wrapped">{{
              jingle.hasMetadata ? jingle.title : jingle.id
            }}</v-card-title>
          </v-img>
        </v-card>
      </v-col>
    </v-row>
    <v-row align-content="space-between" align="center">
      <v-col cols="6" class="pt-0 text-left">
        <FavButton
          :favourites="collections.favourites"
          :id="jingle.id"
          @favouriteToggled="onFavouriteToggled"
        />
        <collection-button
          :collections="collections"
          :id="jingle.id"
          @collectionToggled="onCollectionToggled"
        />
        <span class="faded">{{ jingle.category }}</span>
      </v-col>
      <v-col cols="6" class="pt-0 text-right faded">
        {{ human_duration }}s
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FavButton from "@/components/FavButton";
import CollectionButton from "@/components/CollectionButton";
export default {
  name: "JingleButton",
  props: {
    jingle: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    collections: {
      type: Object,
      required: true,
    },
    newThreshold: {
      type: Number,
      default: 7,
    },
  },

  computed: {
    human_duration: function () {
      const intl = new Intl.NumberFormat("de-DE", {
        maximumSignificantDigits: 3,
      });
      return intl.format(this.jingle.duration);
    },
    img_url: function () {
      return `${process.env.VUE_APP_API}/title/${this.jingle.category}/poster`;
    },
    buttonClass: function () {
      let isNew = this.jingle.isNew(this.newThreshold);
      return isNew ? "new" : "";
    },
  },
  methods: {
    shoutJingle: function (id) {
      this.$emit("jingleClicked", id);
    },
    onFavouriteToggled: function () {
      this.$emit("collectionToggled", this.jingle.id, "favourites");
    },
    onCollectionToggled: function () {
      this.$emit("collectionToggled", ...arguments);
    },
  },
  components: {
    FavButton,
    CollectionButton,
  },
};
</script>

<style scoped>
.faded {
  opacity: 0.2;
}
.wrapped {
  word-break: normal;
}
.new {
  border: 1px solid #ff0;
  box-shadow: 0 0 10px #ff0;
}
</style>
