<template>
  <v-menu absolute offset-y :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon
        ><v-icon class="faded" v-bind="attrs" v-on="on"
          >mdi-playlist-plus</v-icon
        ></v-btn
      >
    </template>

    <v-list dense>
      <v-list-item
        ><v-list-item-title
          >Add/Remove to/from collection</v-list-item-title
        ></v-list-item
      >
      <v-divider></v-divider>
      <v-list-item
        v-for="item in Object.keys(collections)"
        :key="item"
        @click="toggleCollection(item)"
      >
        <v-list-item-content>
          <v-checkbox
            dense
            :value="collections[item]"
            :label="item"
            :value-comparator="isChecked"
          ></v-checkbox>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item>
        <v-text-field
          @change="onNewCollectionChanged"
          label="New Collection"
          v-model="newCollectionValue"
        ></v-text-field>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "CollectionButton",
  data: () => ({
    newCollectionValue: "",
  }),
  props: {
    collections: {
      type: Object,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },

  methods: {
    onNewCollectionChanged: function (newValue) {
      if (newValue.trim() !== "") {
        this.toggleCollection(newValue.trim());
      }
      this.newCollectionValue = "";
    },
    toggleCollection: function (collectionName) {
      this.$emit("collectionToggled", this.id, collectionName);
    },
    isChecked: function (collectionContents) {
      if (!collectionContents) {
        return false;
      }
      return collectionContents.includes(this.id);
    },
  },

  components: {},
};
</script>
