<template>
  <v-row justify="space-between">
    <v-col
      v-for="jingle in items"
      :key="jingle.id"
      :cols="Math.floor(12 / items.length)"
    >
      <JingleButton
        :jingle="jingle"
        :disabled="disabled"
        :collections="collections"
        :newThreshold="newThreshold"
        @jingleClicked="onJingleClicked"
        @collectionToggled="onCollectionToggled"
      />
    </v-col>
  </v-row>
</template>

<script>
import JingleButton from "@/components/JingleButton";
export default {
  name: "name",
  data: () => ({}),
  props: {
    items: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    collections: {
      type: Object,
      required: true,
    },
    newThreshold: {
      type: Number,
      default: 7,
    },
  },
  methods: {
    onJingleClicked: function () {
      this.$emit("jingleClicked", ...arguments);
    },
    onCollectionToggled: function () {
      this.$emit("collectionToggled", ...arguments);
    },
  },
  components: {
    JingleButton,
  },
};
</script>
