<template>
  <v-container>
    <v-virtual-scroll
      :items="materialisedJingles"
      :height="scrollerHeight"
      :item-height="itemHeight"
      class="no-x-scroll"
    >
      <template v-slot:default="{ item }">
        <v-card :class="buttonClass(item)" flat tile ref="ListRow">
          <v-btn icon class="ma-1" @click="onJingleClicked(item.id)">
            <v-icon>mdi-play</v-icon>
          </v-btn>
          <FavButton
            :favourites="collections.favourites"
            :id="item.id"
            @favouriteToggled="onFavouriteToggled(item.id)"
          />
          <collection-button
            :collections="collections"
            :id="item.id"
            @collectionToggled="onCollectionToggled"
          />
          <v-card flat tile class="ma-2">
            <strong>{{ item.category }}</strong>
          </v-card>
          <v-card flat tile class="ma-2">
            {{ item.title }}
          </v-card>
          <v-card flat tile class="ma-2 ml-auto">
            {{ human_duration(item.duration) }}s
          </v-card>
        </v-card>
      </template>
    </v-virtual-scroll>
  </v-container>
</template>

<script>
import FavButton from "@/components/FavButton";
import { JingleIterator } from "@/jingleIterator.js";
import CollectionButton from "@/components/CollectionButton";
export default {
  name: "JingleList",

  data: () => ({
    itemHeight: 100,
  }),

  props: {
    jingles: {
      type: JingleIterator,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    collections: {
      type: Object,
      required: true,
    },
    scrollerHeight: {
      type: Number,
      default: 600,
    },
    newThreshold: {
      type: Number,
      default: 7,
    },
  },

  /* istanbul ignore next */
  mounted() {
    let self = this;
    window.setTimeout(function () {
      if (self.$refs.ListRow) {
        self.itemHeight = self.$refs.ListRow.$el.clientHeight;
      }
    }, 300);
  },

  methods: {
    onJingleClicked: function () {
      this.$emit("jingleClicked", ...arguments);
    },

    onCollectionToggled: function () {
      this.$emit("collectionToggled", ...arguments);
    },

    human_duration: function (value) {
      const intl = new Intl.NumberFormat("en-GB", {
        maximumSignificantDigits: 3,
      });
      return intl.format(value);
    },
    buttonClass: function (jingle) {
      let isNew = jingle.isNew(this.newThreshold);
      let defaultClass = "d-flex flex-row";
      return isNew ? `${defaultClass} new` : `${defaultClass} old`;
    },

    onFavouriteToggled: function (id) {
      this.$emit("collectionToggled", id, "favourites");
    },
  },
  computed: {
    materialisedJingles: function () {
      let output = [];
      for (let item of this.jingles) {
        output.push(item);
      }
      return output;
    },
  },
  components: {
    FavButton,
    CollectionButton,
  },
};
</script>

<style scoped>
.no-x-scroll {
  overflow-x: hidden;
  scrollbar-color: #555 black;
}

.new {
  border-left: 3px solid yellow;
}

.old {
  border-left: 3px solid black;
}
</style>
