<template>
  <v-container fluid>
    <v-row ref="FilterRow" align="stretch">
      <p class="ml-2" v-if="hasFilters">Active filters:</p>
      <v-chip
        v-if="filters.collection.name !== ''"
        color="secondary"
        class="ml-2 mr-2"
        small
        close
        @click:close="resetFilter(FilterName.COLLECTION)"
      >
        Collection: {{ filters.collection.name }}
      </v-chip>
      <v-chip
        v-if="filters.maxDuration > 0.0"
        color="secondary"
        class="ml-2 mr-2"
        small
        close
        @click:close="resetFilter(FilterName.MAX_DURATION)"
      >
        Max duration: {{ filters.maxDuration / 10 }}s
      </v-chip>
      <v-chip
        v-if="filters.category"
        color="secondary"
        class="ml-2 mr-2"
        small
        close
        @click:close="resetFilter(FilterName.CATEGORY)"
      >
        Category match: {{ filters.category }}
      </v-chip>

      <v-chip
        v-if="filters.word"
        color="secondary"
        class="ml-2 mr-2"
        small
        close
        @click:close="resetFilter(FilterName.WORD)"
      >
        Word match: {{ filters.word }}
      </v-chip>
      <v-chip
        v-if="filters.favouritesOnly"
        color="secondary"
        class="ml-2 mr-2"
        small
        close
        @click:close="resetFilter(FilterName.FAVOURITESONLY)"
      >
        Show Favourites Only
      </v-chip>
      <v-chip
        v-if="filters.newOnly"
        color="secondary"
        class="ml-2 mr-2"
        small
        close
        @click:close="resetFilter(FilterName.NEWONLY)"
      >
        Show New Items Only
      </v-chip>
    </v-row>
  </v-container>
</template>

<script>
import { FilterName } from "@/jingleIterator.js";
export default {
  name: "FilterRow",
  data: () => ({
    FilterName: FilterName,
  }),
  props: {
    filters: {
      type: Object,
      required: true,
    },
    hasFilters: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  methods: {
    resetFilter: function (filterName) {
      this.$emit("resetFilter", filterName);
    },
  },
};
</script>
