<template>
  <v-container fluid>
    <v-virtual-scroll
      :items="slicedJingles"
      :height="scrollerHeight"
      item-height="241"
      ref="VirtualScroll"
      class="no-x-scroll"
    >
      <template v-slot:default="{ item }">
        <JingleRow
          @jingleClicked="onJingleClicked"
          @collectionToggled="onCollectionToggled"
          :key="item[0].id"
          :items="item"
          :collections="collections"
          :disabled="disabled"
          :newThreshold="newThreshold"
        />
      </template>
    </v-virtual-scroll>
  </v-container>
</template>

<script>
import JingleRow from "@/components/JingleRow";
import { JingleIterator } from "@/jingleIterator.js";
import { chunkify } from "@/collections.js";

export default {
  name: "Jingles",

  data: () => ({}),

  props: {
    jingles: {
      type: JingleIterator,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    collections: {
      type: Object,
      required: true,
    },
    scrollerHeight: {
      type: Number,
      default: 600,
    },
    newThreshold: {
      type: Number,
      default: 7,
    },
    itemsPerRow: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    slicedJingles: function () {
      let itemsPerRow = this.itemsPerRow;
      if (itemsPerRow < 0 || itemsPerRow > 12) {
        itemsPerRow = 6;
      }
      if (itemsPerRow === 0) {
        if (this.$vuetify.breakpoint.xsOnly) {
          itemsPerRow = 1;
        } else if (this.$vuetify.breakpoint.smAndDown) {
          itemsPerRow = 2;
        } else if (this.$vuetify.breakpoint.mdAndDown) {
          itemsPerRow = 3;
        } else {
          itemsPerRow = 6;
        }
      }
      return chunkify(this.jingles, itemsPerRow);
    },
  },

  methods: {
    onJingleClicked: function () {
      this.$emit("jingleClicked", ...arguments);
    },
    onCollectionToggled: function () {
      this.$emit("collectionToggled", ...arguments);
    },
  },

  components: {
    JingleRow,
  },
};
</script>

<style scoped>
.no-x-scroll {
  overflow-x: hidden;
  scrollbar-color: #555 black;
}
</style>
